<template>
  <input :name="csrfParam" :value="csrfToken" type="hidden" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Rails from '@rails/ujs';

export default defineComponent({
  setup() {
    const csrfParam = Rails.csrfParam();
    const csrfToken = Rails.csrfToken();
    return { csrfParam, csrfToken };
  },
});
</script>
