<template>
  <form method="post" :action="url">
    <input type="hidden" name="_method" value="delete">
    <input-csrf-param />
    <input
      type="text"
      class="form-control verify-value js-clean-target"
      v-model="state.verifyValue"
    >
    <button
      class="btn btn-danger btn-block mt-2"
      data-disable-with='<i class="fas fa-spinner fa-spin"></i>'
      type="submit"
      :disabled="!verified"
      @click="handleClick"
    >
      <slot>削除する</slot>
    </button>
  </form>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from 'vue';
import { questionSubjectFormSessionStorageKey } from '@/lib/utils';
import { questionSubjectPracticeFormStorage } from '@/lib/questionSubjectPracticeFormStorage';
import InputCsrfParam from '@/components/InputCsrfParam.vue';

type Props = {
  url: string
  text: string
  verifyKey: string
  questionSubjectId?: number
}

type State = {
  verifyValue: string
}

export default defineComponent({
  components: { InputCsrfParam },
  props: {
    url: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '削除する',
    },
    verifyKey: {
      type: String,
      required: true,
    },
    questionSubjectId: {
      type: Number,
      default: undefined,
    },
  },
  setup(props: Props) {
    const state = reactive<State>({
      verifyValue: '',
    });
    const verified = computed(() => props.verifyKey === state.verifyValue);
    const handleClick = () => {
      if (verified.value && (props.questionSubjectId != null)) {
        questionSubjectPracticeFormStorage(questionSubjectFormSessionStorageKey(props.questionSubjectId, 'practice')).remove();
      }
    };
    return {
      state, verified, handleClick,
    };
  },
});
</script>
